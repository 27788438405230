<script setup>
const listingId = ref(undefined);
</script>

<template>
  <div class="my-5">
    <h3 class="text-lg font-medium text-indigo-900 leading-normal">Search by Listing ID</h3>
    <form action="" class="flex items-center mt-2 gap-2">
      <input type="text" placeholder="Search" v-model="listingId"
        class="block w-full ring-1 ring-gray-300 ring-inset shadow-sm text-gray-900 text-sm p-3 rounded-md"
        @input="listingId = $event.target.value.replace(/\D/g, '')"
      />
      <NuxtLink :to="localePath({ name: 'listings.show', params: { slug: listingId } })" class="bg-indigo-900 text-white p-2.5 rounded-lg">Search</NuxtLink>
    </form>
    <p class="border-t-2 border-gray-200 relative block my-6 uppercase"> <span
        class="text-[#a19f9f] absolute left-1/2 -translate-x-1/2 bg-[#F5F5F5] top-1/2 -translate-y-1/2 px-6">Or</span>
    </p>
  </div>
</template>